import { ReactNode, FC, useLayoutEffect, useState, useRef } from "react";
import { cx } from "@libs/utils/cx";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
  /**
   * If true, and if the parent element has vertical gap applied, then
   * `CollapsibleSection` will adjust its margin-top to account for the vertical
   * gap so it doesn't take up unwanted whitespace when collapsed.
   */
  adjustVerticalGap?: boolean;
  dataTestId?: string;
};

export const CollapsibleSection: FC<Props> = ({
  children,
  isOpen,
  className,
  adjustVerticalGap,
  dataTestId,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [parentElement, setParentElement] = useState<HTMLElement | null>();

  const parentRowGap =
    adjustVerticalGap && parentElement ? getComputedStyle(parentElement).rowGap : undefined;

  useLayoutEffect(() => {
    // If the parent doesn't have a row gap, no need to adjust our margin-top.
    if (!parentRowGap || !ref.current) {
      return;
    }

    if (isOpen) {
      ref.current.style.removeProperty("margin-top");
    } else {
      ref.current.style.marginTop = `-${parentRowGap}`;
    }
  }, [isOpen, parentRowGap]);

  return (
    <div
      ref={(el) => {
        ref.current = el;

        // The parent element may be `undefined` initially. When it is
        // eventually attached we need to detect it to make `adjustVerticalGap`
        // work, which is why we set the parent in its own state to force a
        // re-render. Refs don't trigger re-renders otherwise.
        setParentElement(el?.parentElement);
      }}
      className={cx(
        "grid transition-all overflow-hidden",
        isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]",
        className
      )}
    >
      <div
        className={cx(
          "self-end min-h-0 transition-opacity",
          isOpen ? "delay-150 opacity-1" : "invisible opacity-0"
        )}
        aria-expanded={isOpen}
        data-testid={dataTestId}
      >
        {children}
      </div>
    </div>
  );
};
