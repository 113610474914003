import React, { forwardRef } from "react";
import { cx } from "@libs/utils/cx";

export const cxStyles = {
  container: "rounded font-sansSemiBold box-border text-center",
  themes: {
    primary: ({ disabled }: { disabled: boolean }) =>
      cx(
        "border",
        disabled
          ? "bg-greyLightest text-greyLight cursor-default border-greyLightest"
          : "border-primaryTheme bg-primaryTheme text-white hover:bg-opacity-70"
      ),
    secondary: ({ disabled }: { disabled: boolean }) =>
      cx(
        "bg-white dark:bg-slate-800 border",
        disabled
          ? "text-greyLighter border-greyLighter dark:text-slate-500 dark:border-slate-600 cursor-default"
          : "border-slate-200 text-primaryTheme dark:border-white dark:text-white dark:hover:text-opacity-70 dark:hover:border-opacity-70 hover:text-opacity-70 hover:border-opacity-70"
      ),
    dangerZone: ({ disabled }: { disabled: boolean }) =>
      cx(
        "border",
        disabled
          ? "bg-greyLightest text-greyLight cursor-default border-greyLightest"
          : "border-red-500 bg-red-500 text-white hover:bg-opacity-70"
      ),
    tertiary: ({ disabled }: { disabled: boolean }) =>
      cx(
        "bg-white border border-greyLighter",
        disabled
          ? "text-greyLighter cursor-default"
          : "hover:border-primaryTheme hover:text-opacity-70 hover:border-opacity-70"
      ),
    reveal: ({ disabled }: { disabled: boolean }) =>
      cx(
        "bg-transparent border border-transparent",
        disabled ? "text-greyLighter cursor-default" : "hover:bg-slate-200 text-primaryTheme"
      ),

    link: ({ disabled }: { disabled: boolean }) =>
      cx(
        disabled
          ? "text-slate-400 cursor-default"
          : "text-primaryTheme dark:text-archyBlue-300 hover:text-opacity-70"
      ),
  },
  sizes: {
    // allows you to define size via class name
    custom: "",
    large: "py-2 px-3 text-base md:text-sm",
    smallSquare: "p-1 text-sm md:text-xs",
    small: "py-1 px-2 text-sm md:text-xs",
    medium: "py-2 px-3 text-sm md:text-xs",
  },
};

export interface ButtonProps {
  theme?: keyof typeof cxStyles.themes;
  size?: keyof typeof cxStyles.sizes;
  // A way to make a button disabled withouth changing the style
  inactive?: boolean;
}

export const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      className,
      children,
      type = "button",
      theme = "primary",
      size = "large",
      inactive,
      disabled = false,
      ...rest
    },
    ref
  ) => (
    <button
      {...{ ...rest, disabled: disabled || inactive }}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={cx(
        cxStyles.container,
        cxStyles.themes[theme]({ disabled }),
        theme !== "link" && cxStyles.sizes[size],
        className
      )}
    >
      {children}
    </button>
  )
);
