import { useEffect } from "react";

export const usePageTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export const formatQueryTitle = <T>(
  query: { isLoading: boolean; isError: boolean; data: T | undefined },
  formatTitle: (data: T) => string,
  options?: { loadingText?: string; errorText: string }
) =>
  query.isLoading
    ? options?.loadingText ?? "Loading..."
    : query.isError
      ? options?.errorText ?? "Error"
      : query.data
        ? formatTitle(query.data)
        : "";
