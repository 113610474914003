import { FC, HTMLAttributes, ReactNode, PropsWithChildren } from "react";
import { MainContent } from "@libs/components/UI/MainContent";

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, "className"> {
  asideContent: ReactNode;
}

export const LeftAsideTemplate: FC<PropsWithChildren<Props>> = ({ asideContent, children, ...props }) => (
  <div {...props} className="flex h-full">
    <div className="flex flex-col w-[296px]">{asideContent}</div>
    <div className="flex-1 min-w-0">
      <MainContent>{children}</MainContent>
    </div>
  </div>
);
