import { FC, HTMLAttributes } from "react";
import { cx } from "@libs/utils/cx";
import { isString } from "@libs/utils/types";
import { FloatingTooltip, FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";

const DISABLED_ICON_COLOR = "text-slate-400";

const cxThemes = {
  darkBackground: "text-white",
  info: "text-slate-400",
  primary: "text-primaryTheme dark:text-archyBlue-300",
  error: "text-red-500",
  success: "text-green-500",
  warning: "text-orange-500",
  darkRed: "text-red-800",
  darkOrange: "text-orange-800",
  darkGreen: "text-green-800",
  yellow: "text-yellow-500",
  slate300: "text-slate-300",
  slate400: "text-slate-400",
  slate500: "text-slate-500",
  slate600: "text-slate-600",
  slate700: "text-slate-700",
  slate900: "text-slate-900",
};

export type IconThemes = keyof typeof cxThemes;

export const cxIconSizes = {
  xs: "h-3 w-3",
  sm: "h-4 w-4",
  md: "h-5 w-5",
  lg: "h-6 w-6",
  xl: "h-7 w-7",
  xxl: "h-8 w-8",
};

export type IconSizes = keyof typeof cxIconSizes;

export type IconProps = {
  SvgIcon: IconComponent;
  className?: string;
  iconClassName?: string;
  iconStyle?: React.CSSProperties;
  theme?: IconThemes;
  size?: IconSizes;
  tooltip?: Omit<FloatingTooltipProps, "children">;
  disabled?: boolean;
  tooltipToAriaLabel?: boolean;
  ariaLabel?: string;
  inline?: boolean;
};

export const Icon: FC<IconProps & HTMLAttributes<HTMLDivElement>> = ({
  theme,
  SvgIcon,
  className,
  iconClassName,
  disabled,
  iconStyle,
  inline,
  tooltip = { content: "" },
  size = "md",
  // By default we use the tooltip content as the aria-label
  // if there is a clickable element above this, that item should own the aria label
  // to ease selection of clickable elements in pendo
  tooltipToAriaLabel = true,
  ...rest
}) => {
  return (
    <FloatingTooltip {...tooltip}>
      <div
        className={cx("items-center justify-center", inline ? "inline-flex" : "flex", className)}
        {...rest}
      >
        <SvgIcon
          role="img"
          style={iconStyle}
          aria-label={
            tooltipToAriaLabel && isString(tooltip.content) ? tooltip.content : tooltip.ariaLabel ?? undefined
          }
          className={cx(
            iconClassName,
            cxIconSizes[size],
            disabled ? DISABLED_ICON_COLOR : theme && !iconStyle?.color && cxThemes[theme]
          )}
        />
      </div>
    </FloatingTooltip>
  );
};
