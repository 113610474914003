import { FC, ReactNode } from "react";

interface Props {
  className?: string;
  children?: ReactNode;
}

export const BoldSpaced: FC<Props> = ({ children, className }) => {
  return (
    <>
      <span className={className}>{children}</span>
      <span className="flex h-0 invisible font-sansSemiBold">{children}</span>
    </>
  );
};
