import { useEffect, useState } from "react";

export const useQuerySelector = <T extends HTMLElement>(selector: string) => {
  const [state, setState] = useState(() => document.querySelector<T>(selector));

  useEffect(() => {
    // in case the queried element is rendered in same render cycle as
    // the component using the hook, we need to wait for the component to mount before
    // querying.

    // If the element was found during component initialization, calling setState is
    // a no-op
    setState(document.querySelector<T>(selector));
  }, [selector]);

  return state;
};
