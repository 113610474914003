import { CSSProperties, FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";

const cxCircleSizes = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "2xs": "h-4 w-4",
  xs: "h-5 w-5",
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-10 w-10",
  xl: "h-14 w-14",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "2xl": "h-[72px] w-[72px]",
};

const cxTextSizes: Record<keyof typeof cxCircleSizes, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "2xs": "text-xxs",
  xs: "text-xxs",
  sm: "text-xxs",
  md: "text-xs",
  lg: "text-sm",
  xl: "text-base",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "2xl": "text-lg",
};

const cxThemes = {
  blue: "bg-archyBlue-500 text-white",
  ["blue-200"]: "bg-blue-200 text-blue-500",
  disabled: "bg-slate-500 text-white",
  magenta: "bg-magenta text-white",
  magentaLight: "bg-magentaLight text-magentaDark",
  ["slate-200"]: "bg-slate-200 text-slate-900",
};

const cxBorderColors = {
  green: "border-green-500",
  ["slate-50"]: "border-slate-50",
  ["slate-300"]: "border-slate-300",
  ["green-800"]: "border-green-800",
  ["red-800"]: "border-red-800",
  white: "border-white",
};

const cxBorderThinkness = {
  thin: "border",
  thick: "border-2",
};

export type Props = {
  size: keyof typeof cxCircleSizes;
  theme?: keyof typeof cxThemes;
  border?: keyof typeof cxBorderColors;
  borderThickness?: keyof typeof cxBorderThinkness;
  className?: string;
  children?: ReactNode;
  customTheme?: Pick<CSSProperties, "backgroundColor" | "color">;
};

export const CharacterIcon: FC<Props> = ({
  children,
  className,
  size,
  theme,
  customTheme,
  border,
  borderThickness,
  ...props
}) => {
  return (
    <div
      className={cx(
        "flex items-center justify-center rounded-full",
        cxTextSizes[size],
        cxCircleSizes[size],
        customTheme || !theme ? undefined : cxThemes[theme],
        border && cxBorderColors[border],
        border && cxBorderThinkness[borderThickness ?? "thin"],
        className
      )}
      style={customTheme}
      {...props}
    >
      {children}
    </div>
  );
};
