import { createContext, useContext } from "react";
import { REACT_RENDER_ROOT } from "@libs/utils/constants";
import { useQuerySelector } from "@libs/hooks/useQuerySelector";

export const PortalContext = createContext(REACT_RENDER_ROOT);

PortalContext.displayName = "PortalIdContext";

export const usePortalId = () => useContext(PortalContext);
export const usePortalElement = () => {
  const portalId = usePortalId();

  return useQuerySelector(`#${portalId}`);
};
