import { isDefined } from "@libs/utils/types";

export type FilterInput = Record<string, unknown>;

type DefaultConfig<T extends FilterInput, K extends StringKeys<T>> = {
  type: "default";
  prop: K;
  format: (val: NonNullable<T[K]>) => string;
};
type ListConfig<T extends FilterInput, K extends StringKeys<T>> = NonNullable<T[K]> extends Array<unknown>
  ? {
      type: "list";
      prop: K;
      format: (val: NonNullable<ListItem<T[K]>>) => string;
    }
  : never;
type CombinedConfig<T extends FilterInput, K extends StringKeys<T>[]> = {
  type: "combined";
  props: K;
  format: (value: T) => string;
};

export type FilterPillConfig<T extends FilterInput, K extends StringKeys<T>> =
  | (K extends StringKeys<T> ? DefaultConfig<T, K> | ListConfig<T, K> : never)
  | CombinedConfig<T, K[]>;

type FilterProps<T extends FilterInput, K extends StringKeys<T>> = {
  id: string;
  label: string;
  prop: K;
};
type FilterValueProps<T extends FilterInput, K extends StringKeys<T>> = {
  id: string;
  label: string;
  prop: K;
  value: ListItem<T[K]>;
};
type CombinedFiltersProps<T extends FilterInput, K extends StringKeys<T>[]> = {
  id: string;
  label: string;
  props: K;
};

export type FilterComponentProps<T extends FilterInput> =
  | FilterProps<T, StringKeys<T>>
  | FilterValueProps<T, StringKeys<T>>
  | CombinedFiltersProps<T, StringKeys<T>[]>;

const toComponentProps = <T extends FilterInput, K extends StringKeys<T>>(
  params: T,
  config: DefaultConfig<T, K>
): FilterProps<T, K> | null => {
  const value = params[config.prop];

  if (isDefined(value)) {
    const label = config.format(value as NonNullable<T[K]>);

    return label
      ? {
          id: config.prop,
          label,
          prop: config.prop,
        }
      : null;
  }

  return null;
};

const toMultiValueComponentsProps = <T extends FilterInput, K extends StringKeys<T>>(
  params: T,
  config: ListConfig<T, K>
): FilterValueProps<T, K>[] | null => {
  const value = params[config.prop];

  if (isDefined(value)) {
    return (value as NonNullable<ListItem<T[K]>>[])
      .map((item) => {
        const label = config.format(item);

        return label
          ? {
              label,
              id: `${config.prop}-${label}`,
              prop: config.prop,
              value: item,
            }
          : null;
      })
      .filter(isDefined);
  }

  return null;
};

const toCombinedComponentProps = <T extends FilterInput, LK extends StringKeys<T>[]>(
  params: T,
  config: CombinedConfig<T, LK>
): CombinedFiltersProps<T, LK> | null => {
  const label = config.format(params);

  return label
    ? {
        id: config.props.join("-"),
        label,
        props: config.props,
      }
    : null;
};

const getEmptyFilterQuery = <T extends FilterInput, K extends StringKeys<T>>(
  config: FilterPillConfig<T, K>[]
): Partial<T> => {
  const result: Partial<T> = {};

  for (const item of config) {
    if ("prop" in item) {
      result[item.prop] = undefined;
    } else {
      for (const prop of item.props) {
        result[prop] = undefined;
      }
    }
  }

  return result;
};

export const toFilterComponentsProps = <
  T extends FilterInput,
  K extends StringKeys<T>,
  Config extends FilterPillConfig<T, K>[],
>(
  params: T,
  config: Config
): { params: T; filters: FilterComponentProps<T>[]; emptyParams: Partial<T> } => {
  return {
    params,
    emptyParams: getEmptyFilterQuery(config),
    filters: config
      .flatMap((configItem) => {
        if (configItem.type === "combined") {
          return toCombinedComponentProps(params, configItem);
        }

        if (configItem.type === "list") {
          return toMultiValueComponentsProps(params, configItem as ListConfig<T, StringKeys<T>>);
        }

        return toComponentProps(params, configItem);
      })
      .filter(isDefined),
  };
};
