import {
  cloneElement,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
  ReferenceType,
  arrow,
  safePolygon,
} from "@floating-ui/react-dom-interactions";
import { MiddlewareData, Strategy } from "@floating-ui/react-dom";
import { createPortal } from "react-dom";
import { cx } from "@libs/utils/cx";
import { usePortalElement } from "@libs/contexts/PortalContext";
import { useClickOutsideTracker } from "@libs/contexts/ClickOutsideListenerContext";

// We have two different types of tooltips and they are applied by using the FloatingTooltip component. The tooltip string is passed in as content and it will default to theme MEDIUM. Pass in theme SMALL to apply.

// Use theme SMALL for informational tooltips. These will typically be very short and approximately 1-3 words. It will apply all the styling for our informational black tooltips that are intended to behave much like browser tooltips. Use sentence case - e.g. `Go Back`

// Use theme MEDIUM for tooltips that provide explanations. This will typically be for some sort of disabled state and provides an answer to why something appears the way it does. This will take on our custom designed tooltip styling and have a white background.

// Use theme CUSTOM if you have completely custom content that you wish to display on hover. This will not apply any default styling.  It will keep the tooltip open while hovering over the tooltip itself.
type TooltipTheme = "SMALL" | "MEDIUM" | "CUSTOM";

// Outline is used instead of border to allow for placement in grids that use "divide-x", otherwise
// the tooltip will be missing the right border due to how divide-x's selector is.
const cxStyles = {
  theme: (theme: Exclude<FloatingTooltipProps["theme"], undefined>) =>
    cx(
      theme === "SMALL" && "bg-greyDark text-white dark:bg-white dark:text-greyDark",
      theme === "MEDIUM" &&
        "bg-white text-greyDark outline outline-1 outline-offset-[-1px] outline-greyLighter shadow-main"
    ),
};

export const useAutoUpdate = ({
  isOpen,
  update,
  refs,
}: {
  isOpen: boolean;
  update: Func;
  refs: {
    reference: MutableRefObject<ReferenceType | null>;
    floating: MutableRefObject<HTMLElement | null>;
  };
}) => {
  useEffect(() => {
    if (refs.reference.current && refs.floating.current && isOpen) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }

    return undefined;
  }, [refs.reference, refs.floating, update, isOpen]);
};

type Split<S extends Placement, D extends string> = S extends `${infer Head}${D}${infer Tail}`
  ? [Head, Tail]
  : never;

const splitPlacement = (placement: Placement) => {
  return placement.split("-") as Split<Placement, "-">;
};

export const useTooltipProps = ({
  className,
  floating,
  placement,
  strategy,
  theme,
  x,
  y,
  maxWidth = true,
}: {
  className?: string;
  floating: (node: HTMLElement | null) => void;
  placement: Placement;
  strategy: Strategy;
  theme: TooltipTheme;
  maxWidth?: boolean;
  x: number | null;
  y: number | null;
}) => {
  const [tooltipPlacement, tooltipAlignment] = splitPlacement(placement);

  const tooltipSmallPlacementPadding = {
    top: "-mt-1",
    right: "ml-1",
    bottom: "mt-1",
    left: "-ml-1",
  }[tooltipPlacement];

  const horizontalOffset = {
    start: -MEDIUM_TOOLTIP_OFFSET,
    end: MEDIUM_TOOLTIP_OFFSET,
  }[tooltipAlignment];

  return useMemo(
    () => ({
      ref: floating,
      className: cx(
        "rounded text-xs text-left z-10",

        cxStyles.theme(theme),
        theme === "MEDIUM"
          ? cx("pt-3 pr-6 pb-4 pl-4 min-w-32", maxWidth && "max-w-[256px]")
          : cx("px-1.5 py-0.5", tooltipSmallPlacementPadding, maxWidth && "max-w-[194px]"),
        className
      ),
      style: {
        position: strategy,
        top: y ?? "",
        left: x ? (theme === "MEDIUM" ? x + (horizontalOffset || 0) : x) : "",
      },
    }),
    [floating, theme, tooltipSmallPlacementPadding, maxWidth, className, strategy, y, x, horizontalOffset]
  );
};

export const useArrowProps = (
  middlewareData: MiddlewareData,
  placement: Placement,
  arrowRef: MutableRefObject<null>,
  theme: TooltipTheme
) => {
  return useMemo(() => {
    const { x: arrowX, y: arrowY } = middlewareData.arrow || {};

    const [arrowPlacement, arrowAlignment] = splitPlacement(placement);

    const arrowPlacementSide = {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    }[arrowPlacement];

    const horizontalOffset = {
      start: MEDIUM_TOOLTIP_OFFSET,
      end: -MEDIUM_TOOLTIP_OFFSET,
    }[arrowAlignment];

    const cxArrowRotation = {
      top: "rotate-[135deg]",
      bottom: "-rotate-45",
      left: "rotate-45",
      right: "rotate-[-135deg]",
    }[arrowPlacement];

    const arrowOffset = theme === "MEDIUM" ? MEDIUM_TOOLTIP_OFFSET - ARROW_OFFSET : 0;

    return {
      ref: arrowRef,
      className: cx(
        "absolute",
        cxStyles.theme(theme),
        cxArrowRotation,
        theme === "MEDIUM" ? "w-4 h-4" : "hidden"
      ),
      style: {
        left: arrowX != null && theme === "MEDIUM" ? `${arrowX + (horizontalOffset || 0)}px` : "",
        top: arrowY == null ? "" : `${arrowY}px`,
        [arrowPlacementSide || "bottom"]: `-${arrowOffset}px`,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%)",
      },
    };
  }, [arrowRef, middlewareData.arrow, placement, theme]);
};

export interface FloatingTooltipProps {
  content: ReactNode;
  placement?: Placement;
  children: JSX.Element;
  className?: string;
  delay?: number | Partial<{ open: number; close: number }>;
  theme?: TooltipTheme;
  initiallyOpen?: boolean;
  forceOpen?: boolean;

  offsetOptions?: Parameters<typeof offset>[0];
  displayArrow?: boolean;
  onVisibleChanged?: (visible: boolean) => void;
  maxWidth?: boolean;
  ariaLabel?: string;
}

const ARROW_OFFSET = 3;
const MEDIUM_TOOLTIP_OFFSET = 10;

export const DEFAULT_DELAY_IN_MS = 500;

const FloatingTooltipContent: React.FC<FloatingTooltipProps> = ({
  children,
  content,
  className,
  delay = { open: DEFAULT_DELAY_IN_MS, close: 0 },
  placement,
  theme = "MEDIUM",
  offsetOptions,
  maxWidth = true,
  ariaLabel,
  forceOpen,
  initiallyOpen = false,
  displayArrow = true,
  onVisibleChanged,
}) => {
  const portal = usePortalElement();
  const [open, setOpen] = useState(initiallyOpen);
  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      onVisibleChanged?.(isOpen);
      setOpen(isOpen);
    },
    [onVisibleChanged]
  );
  const arrowRef = useRef(null);
  const tooltipPlacement = placement ?? (theme === "MEDIUM" ? "top-start" : "top");
  const isOpen = Boolean(forceOpen || open);
  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    refs,
    update,
    middlewareData,
    placement: actualPlacement,
  } = useFloating({
    placement: tooltipPlacement,
    open: isOpen,
    onOpenChange: handleOpenChange,
    middleware: [
      offset(offsetOptions ?? theme === "MEDIUM" ? MEDIUM_TOOLTIP_OFFSET : 0),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay,
      // Use `safePolygon()` to keep tooltip open when hovering over the tooltip itself when the
      // theme is MEDIUM or CUSTOM.
      handleClose: theme === "MEDIUM" || theme === "CUSTOM" ? safePolygon() : null,
    }),
    useFocus(context),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);

  const props = useTooltipProps({
    maxWidth,
    floating,
    theme,
    className,
    strategy,
    x,
    y,
    placement: actualPlacement,
  });
  const arrowProps = useArrowProps(middlewareData, actualPlacement, arrowRef, theme);

  useAutoUpdate({ isOpen, update, refs });

  const tracker = useClickOutsideTracker();

  return (
    <>
      {cloneElement(
        children,
        // The forceOpen here makes sure that even hover doesn't open the tooltip.
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
        forceOpen === false ? undefined : getReferenceProps({ ref: reference, ...children.props })
      )}
      {isOpen && portal
        ? createPortal(
            <div
              role="tooltip"
              aria-label={ariaLabel}
              className="select-none"
              {...getFloatingProps(props)}
              {...tracker}
            >
              {displayArrow && <div {...arrowProps} />}
              {content}
            </div>,
            portal
          )
        : null}
    </>
  );
};

/**
 *
 * Note: If the tooltip doesn't show up on hover, try wrapping `children` in a
 * div.
 */
export const FloatingTooltip: React.FC<FloatingTooltipProps> = (props) =>
  // Only setup tooltip if content exists. Content may be conditionally set, for
  // example, in cases of error tooltips where the error content may or may not
  // exist depending on a validation state.
  props.content ? <FloatingTooltipContent {...props} /> : <>{props.children}</>;
